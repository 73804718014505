import type { ReactNode } from 'react'

type ContainerProps = {
  children: ReactNode
}

type HeaderProps = {
  children: ReactNode
}

type BodyProps = {
  children: ReactNode
}

type FooterProps = {
  children: ReactNode
}

export function Container({ children }: ContainerProps) {
  return (
    <div className="grid h-full w-full grid-rows-[auto_minmax(0,_1fr)_auto] bg-red-300">
      {children}
    </div>
  )
}

export function Header({ children }: HeaderProps) {
  return <header className="flex h-full w-full bg-green-50">{children}</header>
}

export function Body({ children }: BodyProps) {
  return (
    <main className="items-center justify-center overflow-y-auto overflow-x-hidden bg-blue-50">
      {children}
    </main>
  )
}

export function Footer({ children }: FooterProps) {
  return <footer className="flex h-full w-full bg-green-50">{children}</footer>
}
