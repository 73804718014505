import { cva, VariantProps } from 'class-variance-authority'

const messageStyles = cva(['p-3 rounded-lg'], {
  variants: {
    align: {
      left: 'col-start-1 col-end-10',
      right: 'col-start-5 col-end-13',
    },
  },
  defaultVariants: {
    align: 'left',
  },
})

const messageContainerStyles = cva(['flex items-center gap-2'], {
  variants: {
    align: {
      left: 'flex-row',
      right: 'flex-row-reverse',
    },
  },
  defaultVariants: {
    align: 'left',
  },
})

type MessageProps = VariantProps<typeof messageStyles> &
  VariantProps<typeof messageContainerStyles> & {
    message: {
      id: string
      body: string
    }
    avatar?: boolean
  }

export function Message({ message, align, avatar = true }: MessageProps) {
  return (
    <div
      className={messageStyles({ align })}
      key={message.id}
    >
      <div className={messageContainerStyles({ align })}>
        {avatar && (
          <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-500">
            {message.id}
          </div>
        )}
        <div className="relative rounded-xl bg-white px-4 py-2 text-sm shadow">
          <div>{message.body.slice(0, 30)}</div>
        </div>
      </div>
    </div>
  )
}
