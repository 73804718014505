import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'

import { App } from './App.tsx'

const root = document.createElement('div')
root.id = 'root'
document.body.appendChild(root)

// check for query params to enable preview mode
const params = new URLSearchParams(window.location.search)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App preview={params.has('preview')} />
  </React.StrictMode>,
)
