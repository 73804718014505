import type { ReactNode } from 'react'
import { Avatar as AvatarImage } from './Avatar.tsx'

type ContainerProps = {
  children: ReactNode
}

type AvatarProps = {
  name: string
}

type CloseButtonProps = {
  onClick: () => void
}

export function Container({ children }: ContainerProps) {
  return (
    <div className="flex w-full flex-row items-center justify-between px-4 py-2">
      {children}
    </div>
  )
}

export function Avatar({ name }: AvatarProps) {
  return (
    <div className="flex items-center gap-2">
      <AvatarImage />
      <div className="relative ml-3 text-sm">{name}</div>
    </div>
  )
}

export function CloseButton({ onClick }: CloseButtonProps) {
  return (
    <button
      onClick={onClick}
      className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300"
    >
      <svg
        className="h-6 w-6 text-gray-600"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          clipRule="evenodd"
          d="M6 18L18 6M6 6l12 12"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </button>
  )
}
