import { useEffect, useState } from 'react'

export type Message = {
  id: string
  body: string
  email: string
  name: string
}

export function useMessages() {
  const [messages, setMessages] = useState<Message[]>([])

  useEffect(() => {
    const fetchMessages = async () => {
      const response = await fetch(
        'https://jsonplaceholder.typicode.com/comments',
      )

      const data = (await response.json()) as Message[]
      setMessages(data)
    }

    void fetchMessages()
  })

  return [messages] as const
}
