import * as Chat from './components/Chat.tsx'
import * as Header from './components/Header.tsx'

import { MessageList } from './components/MessageList.tsx'
import { Form } from './components/Form.tsx'

import { useMessages } from './hooks/useMessages.ts'
import { useState } from 'react'
import { clsx } from 'clsx'

type AppProps = {
  preview?: boolean
}

export function App({ preview }: AppProps) {
  const [open, setOpen] = useState(false)
  const [messages] = useMessages()

  if (preview) {
    return (
      <div className="h-screen w-screen">
        <Chat.Container>
          <Chat.Header>
            <Header.Container>
              <Header.Avatar name={'Intelidata'} />
            </Header.Container>
          </Chat.Header>
          <Chat.Body>
            <MessageList messages={[]} />
          </Chat.Body>
          <Chat.Footer>
            <Form />
          </Chat.Footer>
        </Chat.Container>
      </div>
    )
  }

  return (
    <div className={clsx('fixed bottom-2 right-2 lg:w-auto', open && 'w-full')}>
      <button
        onClick={() => setOpen(true)}
        className={clsx(
          'rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700',
          open && 'hidden',
        )}
      >
        Open Chat
      </button>
      <div className={clsx('ml-2 h-96 w-full lg:w-[436px]', !open && 'hidden')}>
        <Chat.Container>
          <Chat.Header>
            <Header.Container>
              <Header.Avatar name={'Intelidata'} />
              <Header.CloseButton onClick={() => setOpen(false)} />
            </Header.Container>
          </Chat.Header>
          <Chat.Body>
            <MessageList messages={messages} />
          </Chat.Body>
          <Chat.Footer>
            <Form />
          </Chat.Footer>
        </Chat.Container>
      </div>
    </div>
  )
}
