import { useEffect, useRef } from 'react'
import autoAnimate from '@formkit/auto-animate'

import { Message } from './Message.tsx'

export type MessageListProps = {
  messages: {
    id: string
    body: string
  }[]
}

export function MessageList({ messages }: MessageListProps) {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    containerRef.current && autoAnimate(containerRef.current)
  }, [containerRef])

  return (
    <div
      className="grid h-auto w-full grid-cols-12 gap-y-2 overflow-hidden"
      ref={containerRef}
    >
      {messages.map((message, index) => {
        const isMe = (index + 1) % 2 === 0

        return (
          <Message
            key={message.id}
            message={message}
            align={isMe ? 'right' : 'left'}
            avatar={!isMe}
          />
        )
      })}
    </div>
  )
}
